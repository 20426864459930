// Base colors
$white: #fff;
$black: #000;

// Bamboo
$bamboo1: #b8d94c;
$bamboo2: #9cbe30;
$bamboo3: #6ba410;
$bamboo4: #42860e;
$bamboo5: #356507;

// Coral
$coral1: #fcb7bd;
$coral2: #f58888;
$coral3: #f06158;
$coral4: #c14139;
$coral5: #871a13;

// Fuji
$fuji1: #bbb4cc;
$fuji2: #9e95b3;
$fuji3: #655586;
$fuji4: #584d70;
$fuji5: #3f314f;

// Jade
$jade1: #b1e2e2;
$jade2: #68c2c2;
$jade3: #489b9b;
$jade4: #2c6965;
$jade5: #204c49;

// Orenji
$orenji1: #ffb054;
$orenji2: #ff8e00;
$orenji3: #e3720f;
$orenji4: #bf5500;
$orenji5: #a94500;

// Pebble
$pebble1: #5e6770;
$pebble2: #474d54;
$pebble3: #3a3f45;
$pebble4: #2d3136;
$pebble5: #181a1c;

// Sakura
$sakura1: #ffaddd;
$sakura2: #ee7fc0;
$sakura3: #cb478a;
$sakura4: #a92c6c;
$sakura5: #620e41;

// Sand
$sand1: #f5f5f5;
$sand2: #ececec;
$sand3: #dedede;
$sand4: #a9a9a9;
$sand5: #777777;

// Sun
$sun1: #fdf29d;
$sun2: #fded68;
$sun3: #ffe300;
$sun4: #ffcd00;
$sun5: #ffb800;

// Wave
$wave1: #aee1f5;
$wave2: #71b6ce;
$wave3: #0079a3;
$wave4: #005c7d;
$wave5: #022e50;

// Text color
$text-color: $pebble5;

/****************************************
	z-index
*****************************************/
@function z($widget) {
	$baseIndex: 1000;
	/* Multi-dimensional list.  Each sublist is a new z-index level */
	$z-index-list:
		(callout, autocomplete),
		(alert),
		(modal),
		(guidanceHighlight),
		(guidanceInteraction),
		(guidancePulse),
		(guidance),
		(notification);

	@for $i from 1 through length($z-index-list) {
		@if index(nth($z-index-list, $i), $widget) {
			@return $baseIndex + ($i * 10);
		}
	}
	@error "You must pass in one of the approved values. See '_variables.scss'";
}